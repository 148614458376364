<nz-input-group [nzAddOnAfter]="suffixIconButton" [nzSuffix]="inputClearTpl" nzSearch style="vertical-align: middle">
    <input (ngModelChange)="onChange($event)" [(ngModel)]="searchInput" [nzAutocomplete]="autocomplete"
           nz-input
           placeholder="Suche nach Standort, Gebäude, Anlage..."
           type="text"

    />
</nz-input-group>
<ng-template #inputClearTpl>
    <i
        (click)="searchInput = null"
        *ngIf="searchInput"
        class="ant-input-clear-icon"
        nz-icon
        nzTheme="fill"
        nzType="close-circle"
    ></i>
    <i *ngIf="!searchInput" style="width:12px"></i>
</ng-template>
<ng-template #suffixIconButton>
    <button (click)="search()" nz-button nzSearch nzType="primary"><i nz-icon nzType="search"></i></button>
</ng-template>

<nz-autocomplete #autocomplete (selectionChange)="onSelect($event)" [nzBackfill]="true">
    <nz-auto-optgroup *ngFor="let group of optionGroups" [nzLabel]="groupTitle">
        <ng-template #groupTitle>
            <span>
              {{group.name}}
                <!--                <a class="more-link" [routerLink]="group.type">Show all</a>-->
            </span>
        </ng-template>
        <nz-auto-option *ngFor="let option of group.items" [nzLabel]="option.name"
                        [nzValue]="{option: option, group: group}">
            {{ option.name }}
        </nz-auto-option>
    </nz-auto-optgroup>
</nz-autocomplete>
