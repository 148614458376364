import { Component } from '@angular/core';

@Component({
  selector: 'dh-app-layout',
  templateUrl: './app-layout.component.html',
  styles: [`

    #app-frame {
      position: absolute;
      width: 100vw;
      height: 100vh;
      overflow: hidden;
      display: flex;
      flex-direction: column;
    }

    .app-content {
        max-height: calc(100vh - 50px);
        overflow-x: hidden;
        overflow-y: scroll;
        background-color: #F8F9FA;
    }`

  ]
})
export class AppLayoutComponent {

}
