<div class="flex-200 header-logo" style="cursor: pointer" [routerLink]="['/']">
    <img src="/assets/images/logo-siemens.svg" style="width: 100px"/>
    DATAHUB
</div>
<div class="flex-row flex-auto">

    <div class="flex flex-auto">

    </div>

    <div class="header-search">
        <dh-app-header-search></dh-app-header-search>
    </div>
    <div class="flex flex-auto">
    </div>

    <ul class="header-menu-right" nz-menu nzMode="horizontal">
        <li nz-menu-item nzMatchRouter>
            <nz-badge [nzCount]="2" [nzStyle]="{ backgroundColor: '#009999' }">
                <i nz-icon nzType="bell"></i>
            </nz-badge>
        </li>
        <li (click)="doLogout()" nz-menu-item><i nz-icon nzType="export"></i></li>
    </ul>

</div>
