import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppLayoutComponent} from './app-layout/app-layout.component';

import {AppMenuComponent} from './app-menu/app-menu.component';
import {RouterLink, RouterOutlet} from "@angular/router";
import {AppHeaderComponent} from './app-header/app-header.component';
import {NzLayoutModule} from "ng-zorro-antd/layout";
import {NzMenuModule} from "ng-zorro-antd/menu";
import {NzBadgeModule} from "ng-zorro-antd/badge";
import {NzIconModule} from "ng-zorro-antd/icon";
import { AppHeaderSearchComponent } from './app-header-search/app-header-search.component';
import {NzInputModule} from "ng-zorro-antd/input";
import {FormsModule} from "@angular/forms";
import {NzAutocompleteModule} from "ng-zorro-antd/auto-complete";
import {NzButtonModule} from "ng-zorro-antd/button";


@NgModule({
    declarations: [
        AppLayoutComponent,
        AppMenuComponent,
        AppHeaderComponent,
        AppHeaderSearchComponent
    ],
    imports: [
        CommonModule,
        RouterLink,
        RouterOutlet,
        NzLayoutModule,
        NzMenuModule,
        NzBadgeModule,
        NzIconModule,
        NzInputModule,
        FormsModule,
        NzAutocompleteModule,
        NzButtonModule
    ]
})
export class AppLayoutModule {
}
