import { Component } from '@angular/core';
import {Store} from "@ngxs/store";
import {Logout} from "../../auth/state/auth.actions";

@Component({
  selector: 'dh-app-header',
  templateUrl: './app-header.component.html',
  styles: [`
    :host {
      position: relative;
      font-size: 18px;
      height: 52px;
      padding: 0;
      background-color: #F3F3F0;
      box-shadow: 0 1px 4px rgba(0, 21, 41, 0.2);
      display: flex;
      flex: 0 0 50px;
      z-index: 2000;
    }

    .header-logo {
      padding-left: 24px;
      display: flex;
      align-items: center;
      color: #009999;
      font-size: 14px;
    }

    .header-search {
      display: flex;
      flex: 1 1 auto;
      align-items: center;
    }

    .ant-menu-item {
      height: 50px;
    }

    .ant-menu-item .anticon {
      margin-left: 10px;
      margin-right: 10px;
    }
  `
  ]
})
export class AppHeaderComponent {

    constructor(private store: Store) {

    }

    doLogout(){
        this.store.dispatch(new Logout());
    }

}
