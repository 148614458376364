<div id="app-frame">

    <dh-app-header></dh-app-header>

    <div class="flex-row flex-auto">

        <dh-app-menu></dh-app-menu>

        <div class="flex flex-column flex-auto p-15 app-content">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
